@use '@/scss/underscore' as _;

.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;

  .icon {
    margin-right: _.unit(2);

    > * {
      display: none;

      &:nth-child(1) {
        color: var(--color-type-secondary);
      }

      &:nth-child(2) {
        color: var(--color-brand-default);
      }
    }
  }

  input {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0;
    top: 0;
    margin: 0;
    opacity: 0%;
  }

  input:not(:checked):not(:disabled) ~ .icon > *:nth-child(1),
  input:checked:not(:disabled) ~ .icon > *:nth-child(2),
  input:checked:not(:disabled) ~ .icon > *:nth-child(3) {
    display: block;
  }
}
